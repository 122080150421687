import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import callout from '../../../images/insertion_removal_pg_callout.png'

const metaTags = {
  description: 'Healthcare professionals can learn about the implantation and removal procedure for SUPPRELIN® LA (histrelin acetate) subcuntaneous implant.',
  keywords: 'Homepage',
  title: 'Insertion & Removal Procedure | SUPPRELIN® LA (histrelin acetate)'
}

// const externalLinksMetaTags = {
//   category: 'External Link',
//   action: 'Click',
//   label: 'Deerfield Homepage',
// }

const reference = (
  <>
    <p><strong>Reference: 1.</strong> SUPPRELIN<sup>&reg;</sup> LA [package insert]. Endo USA, Inc.</p>
  </>
)

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true} reference={reference}>
    <Row>
      <Col xs={12}>
        <h1>Insertion and removal procedures</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <h2>Insertion and removal are minimally invasive surgical procedures that may be performed under local anesthetic using aseptic technique.<sup>1</sup></h2>
        <ul className='brand-list'>
          <li>Method of anesthesia utilized (eg, local, conscious sedation, general) is at the discretion of the healthcare professional</li>
          <li>Recommended dose is one 50-mg implant, surgically inserted subcutaneously into the inner aspect of the upper arm once every 12 months</li>
          <li>The implant should be removed after 12 months of therapy</li>
          <ul className='brand-list'>
            <li>
            Another implant may be inserted at the discretion of the treating healthcare professional and the parents or caregivers. Treatment should be discontinued at the appropriate time point for the onset of puberty (approximately 11 years for females and 12 years for males)
            </li>
          </ul>
        </ul>
      </Col>
      <Col xs={12} md={6}>
        <img src={callout} alt="Helpful info share with patients" title="Helpful info share with patients" />
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href={withPrefix("/pdfs/SP-05716_Surgeon_MVA_DIGITAL_final.pdf")}
          LinkCaption="Download full instructions for implanting and removing the SUPPRELIN<sup>&reg;</sup> LA implant - insertion removal"
        >
          Download full instructions for implanting and removing the SUPPRELIN<sup>&reg;</sup> LA implant

        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/procedure/"
          LinkCaption="Learn more about your SUPPRELIN<sup>&reg;</sup> LA Implantation Kit - insertion removal"
        >
         Learn more about your SUPPRELIN<sup>&reg;</sup> LA Implantation Kit
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage;
